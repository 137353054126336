import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import '../theme/bootstrap-grid.css'
import {
  Box,
  Container,
  Flex,
  Heading,
  HeroSection,
  Image,
  Layout,
  LeadSection,
  Markdown,
  ProductTable,
  Text,
  VideoIframe
} from '../components'
import type { ProductTableProps } from '../components/ProductTable/ProductTable'

export interface ProductTemplateProps {
  title: string
  seoTitle: string
  metaDescription: string
  hero: {
    heading: string
    subheading: string
    text: string
    background: string
  }
  lead?: {
    heading: string
    text: string
  }
  details: Array<{
    heading: string
    text: string
    icon: string
  }>
  overview?: {
    heading: string
    markdown: string
    images: Array<{
      image: string
    }>
  }
  table?: ProductTableProps['table']
}

const ProductPageTemplate: React.FC<ProductTemplateProps> = ({
  title,
  seoTitle,
  metaDescription,
  hero,
  lead,
  details,
  overview,
  table
}) => {
  const isVegetableOilPage = !!title.match(/vegetable/i)
  const [videoIframeOpen, setVideoIframeOpen] = useState(false)
  let curveBreakFill: string | Array<string> = 'white'
  if (isVegetableOilPage) {
    curveBreakFill = ['lime', 'lime', 'lime', 'white']
  }

  return (
    <>
      <Helmet>
        <title>Incobrasa | {seoTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <HeroSection
        {...hero}
        hasVideo={isVegetableOilPage}
        onClickPlay={() => setVideoIframeOpen(true)}
        curveBreakFill={curveBreakFill}
      />
      {lead && (
        <Box my="50">
          <Container>
            <LeadSection {...lead} />
          </Container>
        </Box>
      )}
      {overview && (
        <Box bg="lime" pt="xxlarge" position="relative" mb="100">
          <Box
            position="absolute"
            bottom="0"
            left="0"
            right="0"
            bg="white"
            height={[70, 70, 100, 140]}
          />
          <Container position="relative">
            <Heading level="2" mb="xlarge" textTransform="uppercase">
              {overview.heading}
            </Heading>
            <Box px="large">
              <Markdown size="small">{overview.markdown}</Markdown>
              <Box mt="xlarge" className="row">
                {overview.images.map(({ image }) => (
                  <Box key={image} mb="large" className="col col-12 col-sm-6">
                    <Image
                      src={image}
                      alt={image}
                      width="100%"
                      borderRadius="8"
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          </Container>
        </Box>
      )}
      {table && <ProductTable table={table} />}
      <Box>
        <Container>
          <Box className="row">
            {details.map(({ heading, text, icon }) => (
              <Flex key={heading} mb="xxxlarge" className="col col-12 col-md-6">
                <Image
                  width="50"
                  height="50"
                  mr="large"
                  flexShrink="0"
                  src={icon}
                  alt={icon}
                />
                <Box>
                  <Heading level="4" mb="xsmall" textTransform="uppercase">
                    {heading}
                  </Heading>
                  <Text size="small">{text}</Text>
                </Box>
              </Flex>
            ))}
          </Box>
        </Container>
      </Box>
      {videoIframeOpen && (
        <VideoIframe
          src="https://player.vimeo.com/video/351032414?autoplay=1&loop=1&autopause=0"
          onRequestClose={() => setVideoIframeOpen(false)}
        />
      )}
    </>
  )
}

interface ProductPageProps {
  data: {
    markdownRemark: {
      frontmatter: ProductTemplateProps
    }
  }
}

const ProductPage: React.FC<ProductPageProps> = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout transparentNavbarOnTop>
      <ProductPageTemplate {...frontmatter} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query ProductByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        seoTitle
        metaDescription
        title
        hero {
          heading
          subheading
          text
          background
        }
        lead {
          heading
          text
        }
        details {
          heading
          text
          icon
        }
        overview {
          heading
          markdown
          images {
            image
          }
        }
        table {
          images {
            image
          }
          descriptions {
            heading
            text
            images {
              image
              label
            }
          }
        }
      }
    }
  }
`

export { ProductPageTemplate }
export default ProductPage
